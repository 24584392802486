import { LazyAtmCartDrawerTitle, LazyMolCartSummary, LazyOrgCartDrawerContent } from '#components';
import type { CartSummaryProps } from '~/components/mol/CartSummary.vue';
import type { CartDrawerContentProps } from '~/components/org/CartDrawerContent.vue';
import { CheckoutActionType, type ProductPreview } from '~/graphql/generated';

export const useCart = () => {
    const nuxtApp = useNuxtApp();
    const cartStore = useCartStore();
    const route = useRoute();
    const { locale, isoLocale } = useLocales();

    const { cartStatus, checkout, totalItems } = storeToRefs(cartStore);
    const upsellDataMap = new Map<string, UpsellDataType>();
    // expose a function to allow to add values
    const addRecommended = (productId: string, upsellData: UpsellDataType) => upsellDataMap.set(productId, upsellData);

    /** test if a list of handles or single handle matches any product handles in the cart */
    const containsProduct = (idString: string): Ref<boolean> => {
        return computed(() => {
            // console.log('contains Product', idString);
            const ids = idString.split(',');
            for (let i = 0; i < ids.length; i += 1) {
                ids[i] = ids[i].trim();
            }
            // check if we have any product with the given handle
            if (checkout.value?.lineItems) {
                for (const item of checkout.value.lineItems) {
                    if (item.variant?.productRef) {
                        const handle = item.variant.productRef.substring(item.variant.productRef.lastIndexOf(':') + 1);
                        if (ids.includes(handle)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
    };

    /**
     * adds a product and it's items to the cart - always a combination of CMSProduct and CMSProductVariant
     * @param items
     * @param sharedAttributes
     * @throws {Error}
     */
    const addToCart = async (items: (AddToCartItem | undefined)[], sharedAttributes?: Record<string, any>, componentOrigin?: string) => {
        let firstItem: AddToCartItem | undefined;

        const filteredLineItems = items.filter(isDefined);

        const lineItemsLimit = filteredLineItems.some((lineItem) => cartStore.isLimitReached(lineItem.variant.id, lineItem.product.limit));
        if (lineItemsLimit) return;

        const lineItems = filteredLineItems.map((item, index) => {
            if (!index) firstItem = item;

            const props = {
                ...(sharedAttributes || {}),
                ...(item.attributes || {}),
            };

            return {
                variantId: item.variant.id,
                quantity: item.qty,
                props,
            };
        });

        await cartStore.mutationHandler({
            variables: {
                locale: locale.value,
                checkoutId: checkout.value?.id,
                action: checkout.value ? CheckoutActionType.Add : CheckoutActionType.Create,
                lineItems,
            },
            isoLocale: isoLocale.value,
            $gtm: nuxtApp.$gtm,
            trackChanges: true,
            componentOrigin,
        });

        const props = {
            product: firstItem?.product,
        } as CartDrawerContentProps;

        // dont open drawer when on the cart page
        if (!route.path.includes('/cart')) {
            openCartDrawer(props);
        }
    };

    const openCartDrawer = (props?: CartDrawerContentProps | undefined) => {
        nuxtApp.$eventEmitter.emit('open-drawer', {
            title: LazyAtmCartDrawerTitle,
            content: {
                component: LazyOrgCartDrawerContent,
                props: props || {},
            },
            summary:
                totalItems.value > 0
                    ? ({
                          component: LazyMolCartSummary,
                          props: {
                              cartPage: false,
                          } as CartSummaryProps,
                      } as Events.ComponentWithProps)
                    : undefined,
            reset: true,
        });
        return false;
    };

    /**
     * @throws {Error}
     */
    const incrementQuantity = async (items: QuantityData[]) => {
        // console.log('sending quantity data', items);
        await cartStore.mutationHandler({
            variables: {
                locale: locale.value,
                checkoutId: checkout.value?.id,
                action: CheckoutActionType.Update,
                lineItems: items,
            },
            isoLocale: isoLocale.value,
            $gtm: nuxtApp.$gtm,
            trackChanges: true,
        });
    };

    /**
     * @throws {Error}
     */
    const decrementQuantity = async (items: QuantityData[]) => {
        // check if we have any dependencies that need to be removed as well
        // console.log('sending quantity data', items);
        // if one item is removed, remove all of them (affects depending products if they are set)
        const remove = items.some((item) => item.quantity <= 0);

        await cartStore.mutationHandler({
            variables: {
                locale: locale.value,
                checkoutId: checkout.value?.id,
                action: remove ? CheckoutActionType.Remove : CheckoutActionType.Update,
                lineItems: items.map((l: QuantityData) => {
                    if (remove) {
                        return { id: l.id };
                    } else {
                        return l;
                    }
                }),
            },
            isoLocale: isoLocale.value,
            $gtm: nuxtApp.$gtm,
            trackChanges: true,
        });
    };

    return {
        cartBusy: computed(() => cartStatus.value === 'busy'),
        addRecommended,
        addToCart,
        containsProduct,
        incrementQuantity,
        decrementQuantity,
        openCartDrawer,
    };
};

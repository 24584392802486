import { defineStore } from 'pinia';
import type { CheckoutMutation_ManageCheckout_Checkout } from '~/@types/nestedGraphqlTypes';
import { CheckoutActionType, type ProductPreview } from '~/graphql/generated'; // , FeedVariant
import { type CheckoutMutation, type CheckoutMutationVariables } from '~/graphql/generated'; // , FeedVariant
import { GTM } from '~/plugins/gtm.client';
import type { AuthStoreType } from '~/stores/auth';

type CartStatus = 'init' | 'busy' | 'ready' | 'error';

type PreCalcItems = Omit<TSPath<CheckoutMutation_ManageCheckout_Checkout, ['lineItems']>[number], 'id' | 'quantity'> & {
    /**
     * id is fixed to be string (TODO: check provided type by API)
     */
    id: string;
    /**
     * quantity is fixed to be string (TODO: check provided type by API)
     */
    quantity: number;
    shopTitle: string;
    totalDiscount: number;
    quantityPrice: number;
    discountedPrice: number;
    priceString: string;
    compareAtPriceString?: string;
};
type BaseItemData = {
    attributes: Record<string, string | number>;
    compareAtPriceString?: string;
};
type Bundle = BaseItemData & {
    id: string;
    type: 'Bundle';
    items: PreCalcItems[];
    quantity: PreCalcItems['quantity'];
    shopTitle: string;
    image: string;
    lead: string;
    maxQuantity: number;
    totalDiscount: PreCalcItems['totalDiscount'];
    quantityPrice: PreCalcItems['quantityPrice'];
    discountedPrice: PreCalcItems['discountedPrice'];
    priceString: string;
    /**
     * needs to be false =>  only shopify bundles
     */
    isBundle: boolean;
};
type CartItemProduct = PreCalcItems &
    BaseItemData & {
        type: 'Product';
        image?: string | null;
        maxQuantity: number;
    };
export type CartItem = CartItemProduct | Bundle;
type CartStateType = {
    checkout: CheckoutMutation_ManageCheckout_Checkout;
    // do we have a checkout loaded
    loaded: boolean;
    // is the checkout currently processing
    processing: boolean;
    // our current cart
    cartItems: CartItem[];
    // total items we display
    totalItems: number;
    cartStatus: CartStatus;
    cartRecommendations: ProductPreview[];
};

const fallbackCurrency: Record<string, string> = {
    'en-us': 'USD',
    'de-ch': 'CHF',
    'fr-ch': 'CHF',
    default: 'EUR',
};

const getDefaultAppState = (): CartStateType => {
    return {
        checkout: null,
        loaded: false,
        processing: false,
        cartItems: [],
        totalItems: 0,
        cartStatus: 'init',
        cartRecommendations: [],
    };
};

const isBundleCartItem = (cartItem: CartItem): cartItem is Bundle => cartItem.type === 'Bundle';

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useCartStore = defineStore('woom-store-cart', {
    state: () => getDefaultAppState(),
    getters: {
        /**
         * check if product has a limit
         */
        isLimitReached(state) {
            return (productId: string, limit: number) => {
                if (limit < 1) return false;
                let total = 0;
                for (const lineItem of state.checkout?.lineItems ?? []) {
                    if (lineItem.variant?.productRef === productId) {
                        total += lineItem.quantity ?? 0;
                    }
                }
                return total >= limit;
            };
        },
        /**
         * returns all line items that have changed with the new checkout
         */
        getChanges(state) {
            return (checkout?: CheckoutMutation_ManageCheckout_Checkout): { add?: any[]; remove?: any[] } => {
                if (!state.checkout || !checkout)
                    return {
                        add: checkout?.lineItems ?? [],
                    };

                const changes = {
                    add: [] as any[],
                    remove: [] as any[],
                };
                // console.log('existing checkout', state.checkout, checkout);
                for (const lineItem of checkout?.lineItems ?? []) {
                    // do we have this as existing line item
                    const found = this.getLineitemById(lineItem.id as string);
                    // console.log('found existing line item', found);
                    if (found && (lineItem.quantity as number) < (found?.quantity ?? 0)) {
                        // console.log('quantity was reduced', lineItem.quantity);
                        changes.remove.push(lineItem);
                    } else if (found && (lineItem.quantity ?? 0) > (found.quantity ?? 0)) {
                        // console.log('line item quantity increased', lineItem.quantity);
                        changes.add.push(lineItem);
                    } else if (!found) {
                        // console.log('new line item added');
                        changes.add.push(lineItem);
                    }
                }
                // now check for any removals
                for (const lineItem of state.checkout.lineItems) {
                    const found = checkout.lineItems.find((p) => p.id === lineItem.id);
                    if (!found) {
                        console.log('found removed line item', lineItem);
                        changes.remove.push(lineItem);
                    }
                }
                return changes;
            };
        },
        getLineitemById: (state) => (id: string) => state.checkout?.lineItems?.find((lineItem) => lineItem.id === id),
        hasBike: (state) => {
            if (state.cartStatus === 'init') return false;
            return Boolean(
                state.checkout?.lineItems?.find((lineItem) =>
                    lineItem.customAttributes?.find(({ key, value }: { key: string; value: string }) => key === '_isBike' && value === 'true'),
                ),
            );
        },

        getCurrency: (state) => (isoLocale: string) =>
            state.checkout?.subtotalPrice?.currencyCode || fallbackCurrency[isoLocale] || fallbackCurrency['default'],

        getPriceFormated(state) {
            return (isoLocale: string) => {
                const subtotal2 = state.checkout?.subtotalPrice?.amount || '0';
                const currency = this.getCurrency(isoLocale);
                return new Intl.NumberFormat(isoLocale, { style: 'currency', currency }).format(Number.parseFloat(subtotal2));
            };
        },

        getComparePriceFormated(state) {
            return (isoLocale: string) => {
                const subtotal = state.checkout?.lineItemsSubtotalPrice?.amount || '0';
                const subtotal2 = state.checkout?.subtotalPrice?.amount || '0';
                if (subtotal === subtotal2) return;

                const currency = this.getCurrency(isoLocale);
                return new Intl.NumberFormat(isoLocale, { style: 'currency', currency }).format(Number.parseFloat(subtotal));
            };
        },

        /**
         * Static cart-recommendation from Shopify. To be replaced with smarter emarsys
         */
        getCartRecommendations(state) {
            return state.cartRecommendations.filter(
                (item) =>
                    !state.cartItems.some((cartItem) => {
                        if (isBundleCartItem(cartItem)) return false;
                        return cartItem?.variant?.productRef === item.id;
                    }),
            );
        },
    },
    actions: {
        /**
         * @throws {Error}
         */
        async mutationHandler({
            variables,
            isoLocale,
            $gtm,
            processingStatus = 'busy',
            trackChanges = false,
            componentOrigin,
            customChangesHook,
        }: {
            variables: CheckoutMutationVariables;
            isoLocale: string;
            $gtm: GTM;
            processingStatus?: CartStatus;
            trackChanges?: boolean;
            componentOrigin?: string;
            customChangesHook?: (checkout: Exclude<TSPath<CheckoutMutation, ['manageCheckout', 'checkout']>, null | undefined>) => void;
        }) {
            this.cartStatus = processingStatus;
            try {
                const response = await doCheckoutMutation(variables);

                const checkout = response?.data?.manageCheckout?.checkout || null;
                if (checkout) {
                    if (trackChanges && import.meta.client) {
                        if (customChangesHook) {
                            customChangesHook(checkout);
                        } else {
                            const changes = this.getChanges(checkout);
                            console.log('got changes', changes);
                            if (changes.add?.length) $gtm.addCartItems(changes.add, checkout, componentOrigin);
                            else if (changes.remove?.length) $gtm.removeCartItems(changes.remove);
                        }
                    }
                    this.checkout = checkout;
                    this.setCartItems(isoLocale);
                    $gtm.updateCart(checkout);
                }

                const userErrors = response?.data?.manageCheckout?.userErrors || [];
                if (userErrors.length > 0) {
                    // console.log('user error on cart', userErrors);
                    throw new Error(`got user error`, { cause: userErrors });
                }
            } catch (err) {
                this.cartStatus = 'error';
                // console.log('got mutation error', err);
                throw err;
            }
        },
        setCartItems(isoLocale: string) {
            // first run items with prices and discounts
            const preCalcItems: PreCalcItems[] = [];
            let currencyCode;
            let totalItems = 0;
            if (this.checkout?.lineItems) {
                for (const item of this.checkout?.lineItems) {
                    // calculate the discounted price
                    if (!currencyCode && item.variant?.price?.currencyCode) {
                        currencyCode = item.variant.price.currencyCode;
                    }
                    const itemPrice = item.variant?.price?.amount ? Number.parseFloat(item.variant?.price?.amount) : 0;
                    const quant = item.quantity || 1;
                    totalItems += quant;
                    const quantityPrice = itemPrice * quant;
                    let totalDiscount = 0;
                    if (item.discountAllocations) {
                        for (const discount of item.discountAllocations) {
                            const disc = discount.discountedAmount?.amount ? Number.parseFloat(discount.discountedAmount.amount) : 0;
                            totalDiscount += disc;
                        }
                    }
                    const discountedPrice = quantityPrice - totalDiscount;

                    // console.log('got lineitem', itemPrice, quant, quantityPrice, totalDiscount, discountedPrice, item);
                    preCalcItems.push({
                        ...item,
                        /**
                         * id is fixed to be string (TODO: check provided type by API)
                         */
                        id: item.id as string,
                        /**
                         * quantity is fixed to be string (TODO: check provided type by API)
                         */
                        quantity: item.quantity as number,
                        shopTitle: item.shopTitle?.replace(': Default Title', '') ?? '',
                        totalDiscount,
                        quantityPrice,
                        discountedPrice,
                        priceString: new Intl.NumberFormat(isoLocale, { style: 'currency', currency: item.variant?.price?.currencyCode }).format(
                            discountedPrice,
                        ),
                        compareAtPriceString: new Intl.NumberFormat(isoLocale, {
                            style: 'currency',
                            currency: item.variant?.price?.currencyCode,
                        }).format(quantityPrice),
                    });
                }
                // console.log('setting cart items', isoLocale, state.value.checkout?.lineItems, state.value.checkout);
                // here we group bundles together, define associated promo articles and calculate the displayed discounts
                const bundles: Bundle[] = [];
                const items: CartItem[] = [];
                // now that we have the prices and discounts calculated, group products by bundles
                for (const item of preCalcItems) {
                    // do we have a bundle item?
                    // console.log('got lineitem attributes', item.customAttributes);
                    // normalize custom attributes into an object for convenience
                    const attributes: Record<string, number | string> = {};
                    for (const it of item.customAttributes) {
                        if (['_limit'].includes(it.key)) {
                            attributes[it.key] = Number.parseInt(it.value);
                        } else {
                            attributes[it.key] = it.value;
                        }
                    }
                    // find the bundle key
                    const bundleKey = attributes._bundle_key as string;
                    if (bundleKey) {
                        // find a bundle object
                        const bundle = bundles.find((b: any) => b.id === bundleKey);
                        if (!bundle) {
                            const bund: Bundle = {
                                id: bundleKey,
                                type: 'Bundle',
                                items: [item],
                                quantity: item.quantity,
                                shopTitle: attributes.Bundle as string,
                                image: attributes._bundle_image as string,
                                lead: attributes._bundle_lead as string,
                                maxQuantity: 0,
                                totalDiscount: item.totalDiscount,
                                quantityPrice: item.quantityPrice,
                                discountedPrice: item.discountedPrice,
                                attributes: {},
                                priceString: '',
                                isBundle: false,
                            };
                            bundles.push(bund);
                            items.push(bund);
                        } else {
                            // console.log('added to bundle', bundleKey, item);
                            bundle.items.push(item);
                            bundle.totalDiscount += item.totalDiscount;
                            bundle.quantityPrice += item.quantityPrice;
                            bundle.discountedPrice += item.discountedPrice;
                        }
                    } else {
                        const maxQuantity = (attributes._limit as number) || 0;
                        // console.log('max quantity', maxQuantity, typeof maxQuantity);
                        items.push({
                            ...item,
                            type: 'Product',
                            image: item.variant?.shopImage,
                            maxQuantity,
                            attributes,
                            compareAtPriceString: item.priceString === item.compareAtPriceString ? undefined : item.compareAtPriceString,
                        });
                    }
                }
                // now update all bundle prices
                for (const bundle of bundles) {
                    bundle.priceString = new Intl.NumberFormat(isoLocale, { style: 'currency', currency: currencyCode }).format(
                        bundle.discountedPrice,
                    );
                    bundle.compareAtPriceString = new Intl.NumberFormat(isoLocale, { style: 'currency', currency: currencyCode }).format(
                        bundle.quantityPrice,
                    );
                    if (bundle.priceString === bundle.compareAtPriceString) {
                        bundle.compareAtPriceString = undefined;
                    }
                    for (const item of bundle.items) {
                        bundle.attributes[item.shopTitle] = item.variant?.shopTitle === DEFAULT_TITLE ? '' : (item.variant?.shopTitle ?? '');
                    }
                    // console.log('got bundle attributes', bundle.attributes);
                }
                // console.log('got cart items', items);
                this.cartItems = items;
                this.totalItems = totalItems;
            }
            this.cartStatus = 'ready';
        },

        async cartCollection({ language, country }: { language: Ref<string>; country: Ref<string> }) {
            if (this.cartRecommendations.length) {
                return;
            }

            const id = `${country.value}:${language.value}:collection:cart-recommendation`;

            try {
                const data = await getCollectionQueryData({ id });
                const collection = data?.data?.collection?.products?.items;
                this.cartRecommendations = (collection || []) as ProductPreview[];
            } catch (error) {
                console.error('Error fetching Shopify cart-recommendation collection:', error);
            }
        },

        async fetch({
            locale,
            isoLocale,
            checkoutId,
            $gtm,
            authStore,
        }: {
            locale: string;
            isoLocale: string;
            checkoutId?: string | number | undefined;
            $gtm: GTM;
            authStore: AuthStoreType;
        }) {
            authStore.checkToken(); // refreshes JWT from cookie
            checkoutId = checkoutId || authStore.jwt?.cartId;
            if (!checkoutId) {
                this.cartStatus = 'ready';
                $gtm.updateCart(null);
                return;
            }

            await this.mutationHandler({
                variables: {
                    locale,
                    checkoutId: `${checkoutId}`,
                    action: CheckoutActionType.Fetch,
                },
                isoLocale,
                $gtm,
                processingStatus: 'init',
            });
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
